import React, {useState, useEffect} from 'react'
import { useMatch } from 'react-router-dom'
import {GetDatafromOrder, GetRepuesto, addPedidoRepuesto, GetProducts} from './dbfirebase'
import {Table} from './table'
import {columnsRepuestosGarantia,columnsRepuestosConfirmar} from './columns';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import deleterow from '../images/delete.png'
import { useGeneral } from '../context/generalContext'

//Repuestos pedidos por garantia sobre una orden de servicio

export const Repuestos = () => {

    const navigate = useNavigate();
    const { userData, generateSecretKey } = useGeneral()
    const [dato, setDato] = useState()
    const [repuesto, setRepuesto] = useState()
    const [pedido, setPedido] = useState([])
    const [listaPedido, setListaPedido] = useState([])
    const [pedidofiltrado, setPedidofiltrado] = useState()
    const [prod, setProd] = useState()
    const [userdatos, setUserdatos] = useState()
    const [verprod, setVerprod] = useState(false)

    const match = useMatch('/repuestos/:id')
    const id = match.params.id

    const perfil = async () => {
        const dato = await userData
        setUserdatos(dato)
    }

    setTimeout(perfil, 1000)

    const orden = async() =>{
        setDato(await GetDatafromOrder(id))
    } 

    const repuestos = async() =>{
        if(dato != undefined){
            const repu = await GetRepuesto(dato.Categoria, dato.Articulo)
            setRepuesto(repu)
        }  
    }

    const GetProduct = async() =>{
        const produc = await GetProducts()
        setProd(produc)
    }

    const EliminarRepuesto = (r) => {
        setPedidofiltrado((prevPedido) => {
          const index = prevPedido.indexOf(r);
          if (index !== -1) {
            const newPedido = [...prevPedido];
            newPedido.splice(index, 1);
            return newPedido;
          }
          return prevPedido;
        });
      };

    useEffect(()=>{
        setListaPedido([...listaPedido, pedido])
    },[pedido])

    useEffect(()=>{
        orden()
        GetProduct()
    },[])
    
    useEffect(()=>{
        repuestos()
    },[dato])
    
    const nuevoArreglo = [];
    if (repuesto){
        const objetoCombinado = [repuesto.repuestos.reduce((resultado, objeto) => {
            return { ...resultado, ...objeto };
          }, {})];
        
        // Creamos una copia del objeto combinado
        let objetoActualizado = { ...objetoCombinado };
        if (userdatos?.Permiso === "Garante" && verprod) {
        //Anexar los sku para disponibilizar productos de cambio. Validar con permiso garante
            objetoActualizado = {
                ...objetoCombinado,
                ...prod
            };
        }

        for (let clave in objetoActualizado[0]) {
               
                nuevoArreglo.push({
                    Fecha: new Date().toLocaleString().split(',')[0],
                    Articulo: clave,
                    Descripcion: objetoActualizado[0][clave].Descripción,
                    PrecioUsd: objetoActualizado[0][clave].PrecioU$D,
                    Estado: "Pendiente",
            });
        }
    }
    

    const filtrado = () => {
        const filteredPedido = listaPedido.flat().filter(item => item !== undefined);
      
      
        // Crear un objeto para almacenar los últimos registros de cada artículo
        const uniqueItems = {};
      
        // Iterar sobre los registros y guardar el último para cada artículo
        filteredPedido.forEach(item => {
          if (item.cantidad !== "0") {
            const currentTimestamp = new Date().getTime();
            item.Fecha = new Date().toLocaleString().split(',')[0];
            item.Ult_Act = currentTimestamp;
            // Actualizar el timestamp y el producto solo si el artículo es nuevo o el timestamp actual es mayor
            if (!uniqueItems[item.Articulo] || uniqueItems[item.Articulo].Ult_Act < currentTimestamp) {
              
              item.Sku = document.getElementById("Articulo").value;
            }
      
            // Actualizar la cantidad solo si es mayor que la cantidad actual
            const currentCantidad = parseInt(item.cantidad);
            if (!uniqueItems[item.Articulo] || currentCantidad > parseInt(uniqueItems[item.Articulo].cantidad)) {
              item.cantidad = currentCantidad.toString();
            }
      
            uniqueItems[item.Articulo] = item;
      
    
          } else {
            delete uniqueItems[item.Articulo]; // Eliminar el artículo si la cantidad es 0
          }
        });
      
        // Convertir el objeto único en un array
        const uniquePedido = Object.values(uniqueItems);
      
        setPedidofiltrado(uniquePedido);
        return uniquePedido;
      };
      
      
    
    const AddSparepartsRequest = async() =>{
        const pedidosActualizados = pedidofiltrado.map(pedido => ({
            ...pedido,
            UltEstado: "Pendiente"
          }));
        const upload = await addPedidoRepuesto(id, pedidosActualizados)
        setListaPedido('')
        setPedido([''])
        navigate('/ordenes')
       
    }
   
   
    return(
        <div>
            <ToastContainer/>
            <h1>
                Solicitud de repuestos:
            </h1>
            <br/>
            {dato && <div className='ms-4 row'>
                <div className='col-lg-6 d-flex ms-auto mb-3' style={{alignItems: 'center' }} >
                    <label style={{margin:'0 1rem',maxWidth:'5rem',minWidth:'5rem'}}>Numero de Orden:</label>
                    <input 
                        className= "form-control"
                        style={{maxWidth:'25rem'}}
                        value = {dato.iddoc}
                        disabled={true}
                    />
                </div>
                <div className='col-lg-6 d-flex ms-auto mb-3 justify-content-center' style={{alignItems: 'center' }} >
                    <label style={{margin:'0 1rem',maxWidth:'5rem',minWidth:'5rem'}}>Nombre:</label>
                    <input 
                        className= "form-control"
                        style={{maxWidth:'25rem'}}
                        value = {dato.Cliente}
                        disabled={true}
                    />
                </div>
                <div className='col-lg-6 d-flex ms-auto mb-3' style={{alignItems: 'center' }} >
                    <label style={{margin:'0 1rem',maxWidth:'5rem',minWidth:'5rem'}}>Email:</label>
                    <input 
                        className= "form-control"
                        style={{maxWidth:'25rem'}}
                        value = {dato.Email_cli}
                        disabled={true}
                    />
                </div>
                <div className='col-lg-6 d-flex ms-auto mb-3 justify-content-center' style={{alignItems: 'center' }} >
                    <label style={{margin:'0 1rem',maxWidth:'5rem',minWidth:'5rem'}}>Telefono:</label>
                    <input 
                        className= "form-control"
                        style={{maxWidth:'25rem'}}
                        value = {dato.Telefono_cli}
                        disabled={true}
                    />
                </div>
                <div className='col-lg-6 d-flex ms-auto mb-3' style={{alignItems: 'center' }} >
                    <label style={{margin:'0 1rem',maxWidth:'5rem',minWidth:'5rem'}}>Articulo:</label>
                    <input 
                        className= "form-control"
                        style={{maxWidth:'25rem'}}
                        value = {dato.Articulo}
                        disabled={true}
                        id = "Articulo"
                    />
                </div>
                <div className='col-lg-6 d-flex ms-auto mb-3 justify-content-center' style={{alignItems: 'center' }} >
                    <label style={{margin:'0 1rem',maxWidth:'5rem',minWidth:'5rem'}}>Falla:</label>
                    <input 
                        className= "form-control"
                        style={{maxWidth:'25rem'}}
                        value = {dato.Desperfecto}
                        disabled={true}
                    />
                </div>
                <label style={{margin:'0 1rem',minWidth:'5rem', marginTop:'1rem'}}>Observaciones sobre el estado del producto:</label>
                <div className=' d-flex ms-auto mb-3' style={{alignItems: 'center', marginTop:'1rem' }} >
                    
                    <input 
                        className= "form-control"
                        value = {dato.Comentarios}
                        disabled={true}
                    />
                </div>
                <label style={{margin:'0 1rem',minWidth:'5rem',marginTop:'1rem' }}>Repuestos ya solicitados para este documento:</label>
                <div className='col-lg-12 d-flex ms-auto mb-3' style={{alignItems: 'center', marginTop:'1rem' }} >
                    
            {dato && <table className="table table-bordered">
                        <thead>
                            <tr>
                            <th>Fecha</th>
                            <th>Artículo</th>
                            <th>Cantidad</th>
                            <th>Estado</th>
                            <th>Estado</th>
                            </tr>
                        </thead>
                        <tbody>
                            {dato.Repuestos.map((repuestos, index) => (
                            <tr key={index}>
                                <td>{repuestos.Fecha}</td>
                                <td>{repuestos.Articulo}</td>
                                <td>{repuestos.cantidad}</td>
                                <td>{repuestos.UltEstado}</td>
                                <td>{repuestos.UltEstado}</td>
                            </tr>
                            ))}
                        </tbody>
                    </table>}

                </div>
            </div>}
            <br/>
            <div style={{marginTop:'30px'}}>
                {userdatos?.Permiso === "Garante" &&
                    <div className="d-flex justify-content-end">
                        <div className="form-check form-switch d-flex justify-content-end">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                id="flexSwitchCheckDefault"
                                checked={verprod}
                                onChange={(e) => setVerprod(e.target.checked)}
                            />
                            <label className="form-check-label" style={{marginLeft: "2rem"}} htmlFor="flexSwitchCheckDefault">
                                Listar productos para cambio
                            </label>
                        </div>
                    </div> 
                }
                 {repuesto && <Table listaOrdenes={nuevoArreglo} source={"orden"} rows={nuevoArreglo.length} settingcolumns={columnsRepuestosGarantia} globalfilter={true} statefilter={false} exporttable={false} showRequest={true} showQuantity2={true} pedido={pedido} setPedido={setPedido}/>}  
            </div>


            <button className="btn btn-secondary col-12" onClick={filtrado}>
                Cargar pedido
            </button>

            <div style={{marginTop:'30px'}} className='table-responsive'>
                <h5>
                    Pre-visualizacion de los repuestos a solicitar
                </h5>
{pedidofiltrado && <table className="table table-striped table-hover" style={{textAlign: "center"}}>
                    <thead className="thead-light">
                        <tr>
                        <th>Fecha</th>
                        <th>Repuesto</th>
                        <th>Descripción</th>
                        <th>Cantidad</th>
                        <th>Eliminar</th>
                        </tr>
                    </thead>
                    <tbody>
                        {pedidofiltrado.map((fila, index) => (
                        <tr key={index} >
                            <td>{fila.Fecha}</td>
                            <td>{fila.Articulo}</td>
                            <td>{fila.Descripcion}</td>
                            <td>{fila.cantidad}</td>
                            <td>
                            <button onClick={() => EliminarRepuesto(fila)} style={{border: 'none' }} >
                                <img src={deleterow} alt="Eliminar" style={{ width: '35px', height: '35px', border: 'none' }}/>
                            </button>
                            </td>
                        </tr>
                        ))}
                    </tbody>
                    </table>}
                {/* {pedidofiltrado && <Table showDelete={true} listaOrdenes={pedidofiltrado} source={"orden"} settingcolumns={columnsRepuestosConfirmar} exporttable={true} showRequest={true}/>} */}

            </div>

            <button className="btn btn-success col-12" onClick={AddSparepartsRequest} style={{marginTop:'30px'}}>
                Confirmar
            </button>
            {/* {pedidofiltrado && <ol style={{ display: 'flex', flexWrap: 'wrap', listStyle: 'none', margin: 0, padding: 0 }}>
                                {pedidofiltrado && Object.values(pedidofiltrado).map((r, i) => {
                                    return(
                                        <li key={`${r + i}`} style={{ padding: '0.5rem', marginRight: '0.5rem', marginBottom: '0.5rem' }}>
                                            
                                            <div style={{ display: 'flex', alignItems: 'center',  padding: '1rem', position: 'relative' }}>
                                                <pre style={{ marginRight: '0.5rem' }}>{JSON.stringify(r, null, 2)}</pre>
                                                    <button
                                                        type="button"
                                                        onClick={() => {EliminarRepuesto(r);}}
                                                        style={{
                                                        backgroundColor: 'red',
                                                        color: '#fff',
                                                        borderRadius: '50%',
                                                        border: 'none',
                                                        width: '1.5rem',
                                                        height: '1.5rem',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        position: 'absolute',
                                                        top: 0,
                                                        right: 0,
                                                        cursor: 'pointer'
                                                        }}
                                                    >
                                                    x
                                                    </button>
                                            </div>
                                        </li>
                                    )
                                })}
                            </ol>} */}
        </div>
    )

}