import React, { useRef, useEffect } from 'react';
import {useAuth} from "../context/authcontext";
import { useSelector } from 'react-redux';
import cerrarsesion from '../images/cerrar-sesion.png'
import {Link} from 'react-router-dom'
import { analytics } from "../components/firebase"; // Asegúrate de ajustar la ruta según sea necesario
import { logEvent } from "firebase/analytics";

export function Showafterloged(){

    const {user, logout} = useAuth();
    const menuRef = useRef();
    const userdatos = useSelector(state => state.userInfo)

    const handlelogout = async () => {
        try{
            await logout();
        }catch (error) {
            console.log(error)
        }
    };

    const registrarEvento = (nombreLink) => {
        logEvent(analytics, nombreLink, {
          seccion: nombreLink,
          tiempo: new Date().toISOString()
        });
    };
    
    if(user){
        return(
            <div className='row'>
                <div className="col-10 collapse navbar-collapse justify-content-center" id="navbarNav" ref={menuRef} style={{ paddingLeft: 0 }}>

                    <ul className="navbar-nav mx-2">
                        <li className="nav-item" >
                            <Link className="nav-link hogar" to="/micuenta" onClick={() => registrarEvento('Mi Cuenta')} >Mi cuenta</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link cocina" to="/ordenes" onClick={() => registrarEvento('Ordenes')} >Ordenes</Link>
                        </li>

                        {/* Validacion eventual porque este componente permanece oculto a los talleres externos */}
            {userdatos.AccessUUID === 'w3tths9rxmgopRJXmYwI5Mx01ee2'?
                        <li className="nav-item">
                            <Link className="nav-link desayuno" to="/repuestos" onClick={() => registrarEvento('Pedidos')} >Pedidos</Link>
                        </li>
            :''}
            
                        <li className="nav-item">
                            <Link className="nav-link climatizacion" to="/estado_pedidos" onClick={() => registrarEvento('Estados')} >Estados</Link>
                        </li>
            
            {userdatos.UUID === '3LpsN2VwJHMFLv49KJOqUmo44Mz2'?
                        ''
                :
                <li className="nav-item">
                    <Link className="nav-link linea-blanca" to="/facturacion" onClick={() => registrarEvento('Facturacion')} >Facturacion</Link>
                </li>}

                    </ul>
                </div>

            <div className="col-2 collapse navbar-collapse justify-content-end" id="navbarNav">   
                <div className="flex-column ocultar-div" align="left" style={{lineHeight:'0.5rem'}}>
                    {/* <p>Reparaciones SRL</p> */}
                    <p>{user?user.email:'Loading'}</p>
                </div>
                <button className="btn btn-light btn-sm mx-1"
                            onClick = {handlelogout}
                            style={{margin: '0 auto', border:'none',backgroundColor: '#ffffff'}}>
                            <img src={cerrarsesion} width="35rem" height="35rem"/>
                </button>
            </div>
        </div>
    )}
}