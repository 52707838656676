import React, { useMemo, useEffect, useState, useRef } from 'react';
import { useTable, useSortBy, usePagination, useRowSelect, useGlobalFilter } from 'react-table';
import { NavLink, useNavigate } from 'react-router-dom';
import { GlobalFilter, StateFilter,StateFilterRepuestos,DateFilter } from './globalfilter'
import left from '../images/left.png'
import next from '../images/next.png'
import { ExportTable } from './exportTable'
import { OrdenModal } from './modal'
import { Actualizar } from './dbfirebase'
import { useDispatch, useSelector } from 'react-redux';
import { addNewOrder, addSparepart } from '../store/ordersInfo/newOrderSlices';
import edit from '../images/edit.png'

export const Table = (
    {
        showDetail, showComponents, showDetailorder, showDelete, listaOrdenes,
        source, settingcolumns, globalfilter, statefilter, exporttable, setUpdate,
        showRequest, showQuantity, showQuantity2,showQuantity3, pedido, setPedido, rows, articuloSeleccionado,statefilterrepuestos, filterDate, showEdit, showSumar,
        setOrdenesSeleccionadas
    }
) => {

    const navigate = useNavigate();

    // datos del pedido seleccionado
    const [documentInfo, setDocumentInfo] = useState({})

    //Guardamos los datos del pedido
    const [selecteddata, setSelecteddata] = useState([]);

    // datos del producto seleccionado
    const [productComponents, setProductComponents] = useState()

    const [isOn, setIsOn] = useState(false);

    const [repuestos, setRepuestos] = useState([])

    //useMemo evita que en cada renderizado se vuelvan a llamar los datos
    const columns = useMemo(() => settingcolumns, [])
    const data = useMemo(() => listaOrdenes, [listaOrdenes])

    const dispatch = useDispatch()

    //Definimos la cantidad de filas que muestra la tabla.
    const numberofRows = rows||100
  
    //Referencia a la tabla
    const tableRef = useRef(null)

    const handleSelectChange = (event, datosfila) => {
        const { value } = event.target;
        setPedido([...pedido, { ...datosfila, cantidad: value }])
    };

   

    const handleQuantityChange = (event, datosfila) => {
         
        // console.log(datosfila)
        let repObj = {
            id: "test",
            Sku: articuloSeleccionado,
            Articulo: datosfila.Articulo,
            Descripcion: datosfila.Descripcion,
            PrecioPublico: datosfila.PrecioPublico,
            PrecioTaller: datosfila.PrecioTaller,
            cantidad: parseInt(event.target.value)
        }

        dispatch(addSparepart(repObj))
    }

    useEffect(() => {
        let lista = []
        selecteddata.map((i) => {
            lista.push(i.CodProducto)
        })
        setProductComponents(...lista)

    }, []);

    //Setea el hook setDocumentInfo para renderizar un modal
    const productDetail = (rowData) => {
        setDocumentInfo(rowData)
    }

    //funcion que ejecuta al clickear detalles
    const handleDetails = (rowData) => {
        
        productDetail(rowData)
    }

    const ModificarOrden = (id) => {
        navigate(`/ordenes/modificaciones/:${id}`)
    }

    const SelectOrder = (rowData) =>{
        setOrdenesSeleccionadas((prevdata) => [...prevdata, rowData])
    }

    //Columna de detalle
    const addDetailCol = (hooks) => {
        if (showDetail) {
            hooks.visibleColumns.push((columns) => [
                ...columns,
                {
                    id: "detalles",
                    Header: "Detalle",
                    Cell: ({ row }) => (
                        <div>
                            {(source === "orden") &&
                                <a href="#" className="detailsLink" onClick={() => { handleDetails(row.original) }} data-bs-toggle="modal" data-bs-target="#Modal">
                                    Ver
                                </a>
                            }
                        </div>
                    )
                }])
        }
    }


    const addCheckButton = (hooks) => {
        if (showQuantity3) {
            hooks.visibleColumns.push((columns) => [
                ...columns,
                {
                    id: "cantidad",
                    Header: "Cantidad",
                    Cell: ({ row }) => (
                        <input 
                            checked={row.values.Check} 
                            type='checkbox'
                            onChange={(event) => {
                                if (event.target.checked) {
                                    handleQuantityChange({ ...event, target: { ...event.target, value: "1" } }, row.original);
                                } else {
                                    // Acción cuando se desmarca, por ejemplo, borrar el valor
                                    handleQuantityChange({ ...event, target: { ...event.target, value: "0" } }, row.original);
                                }
                            }}
                        />
                    )
                }
            ])
        }
    }
    
    // para desactivar eliminar el objeto con un boton de eliminar

    const checkButtonOff = (hook) => {
       
    }
    

    
    //Columna de detalle
    const addQuantity = (hooks) => {
        if (showQuantity) {
            hooks.visibleColumns.push((columns) => [
                ...columns,
                {
                    id: "Quantity",
                    Header: "Cantidad",
                    Cell: ({ row }) => (
                        <select value={row.original.cantidad}
                            // onChange={(event) => handleSelectChange(event, row.values)}
                            onChange={(event) => handleQuantityChange(event, row.original)}
                        >
                            <option value="0">0</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                        </select>
                    )
                }
            ])
        }
    }

    //Columna de detalle
    const addQuantity2 = (hooks) => {
        if (showQuantity2) {
            hooks.visibleColumns.push((columns) => [
                ...columns,
                {
                    id: "Quantity",
                    Header: "Cantidad",
                    Cell: ({ row }) => (
                        <select defaultValue="0"
                            onChange={(event) => handleSelectChange(event, row.values)}
                            //onChange={(event) => handleQuantityChange(event, row.original)}
                        >
                            <option value="0">0</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                        </select>
                    )
                }
            ])
        }
    }

    const Modificar = (hooks) => {
        const userdatos = useSelector(state => state.userInfo);
        if (userdatos.Permiso !== 'empleado' && showEdit) {
            hooks.visibleColumns.push((columns) => [
                ...columns,
                {
                    id: "Edit",
                    Header: "Modificar",
                    Cell: ({ row }) => {
                        if (row.original.Estado == "Envio a central" || row.original?.EnviadoAGoldmund === true) {
                            return null; // No renderizar si el estado es "En central"
                        }
                        return (
                            <div className='d-flex justify-content-center'>
                                {/* <button onClick={() => {ModificarOrden(row.original.iddoc)}}>
                                    Modificar
                                </button> */}
                                <img
                                    src={edit}
                                    alt='edit'
                                    style={{ cursor: 'pointer', width: '30px', height: '30px' }}
                                    onClick={() => { ModificarOrden(row.original.iddoc) }}
                                />
                            </div>
                        );
                    }
                }
            ]);
        }
    };


    //Columna de detalle
    // const Cargar = (hooks) => {
    //     if (showSumar) {
    //         hooks.visibleColumns.push((columns) => [
    //             ...columns,
    //             {
    //                 id: "Sumar",
    //                 Header: "Facturar",
    //                 Cell: ({ row }) => (
    //                     <div className='d-flex justify-content-center'>
    //                         <div className="form-check">
    //                             <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" onClick={() =>{SelectOrder(row.original)}}/>
    //                         </div>
    //                     </div>
    //                 )
    //             }
    //         ])
    //     }
    // }

    const Cargar = (hooks) => {
        if (showSumar) {
            hooks.visibleColumns.push((columns) => [
                ...columns,
                {
                    id: "Sumar",
                    Header: "Facturar",
                    Cell: ({ row }) => (
                        <div className='d-flex justify-content-center'>
                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value=""
                                    id="flexCheckDefault"
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            const { iddoc, ManoDeObra } = row.original;
                                            const dataRow = { iddoc, ManoDeObra };
                                            SelectOrder(dataRow);
                                        }else{
                                            const { iddoc, ManoDeObra } = row.original;
                                            const dataRow = { iddoc, ManoDeObra };
                                            const updatedRow = { ...dataRow, ManoDeObra: -dataRow.ManoDeObra, delete: true};
                                            SelectOrder(updatedRow);
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    )
                }
            ])
        }
    }
    

    


    //Configuracion de las funciones de la tabla
    const tableInstance = useTable({
        columns,
        data,
        initialState: { pageSize: numberofRows }
    },
        useGlobalFilter,
        useSortBy,
        usePagination,
        useRowSelect,
        addDetailCol,
        addQuantity,
        addQuantity2,
        Modificar,
        Cargar,
        addCheckButton,
      
    );
     // addCheckButton
    //Seleccion de las instancias que requiere la tabla
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        nextPage,
        previousPage,
        canPreviousPage,
        canNextPage,
        state,
        prepareRow,
        selectedFlatRows,
        pageCount: controlledPageCount,
        setGlobalFilter,
    } = tableInstance

    const { pageIndex, globalFilter } = state

    //renderizado de tabla con filtros y checkbox
    return (
        <div>
            <OrdenModal data={documentInfo} />

            <div className="d-flex justify-content-evenly contenedor">
                <div className="col-6">
                    {globalfilter && <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />}
                </div>
                <div className="col-2">
                    {statefilter && <StateFilter filter={globalFilter} setFilter={setGlobalFilter} />}
                    {statefilterrepuestos && <StateFilterRepuestos filter={globalFilter} setFilter={setGlobalFilter} />}
                </div>
                <div className="col-2">
                    {filterDate && <DateFilter filter = {globalFilter} setFilter = {setGlobalFilter}/>} 
                </div>
                <div className="col-2 d-flex justify-content-end">
                    {exporttable && <ExportTable tablereference={tableRef} />}
                </div>
            </div>
            <br />
            <div className="shadow-lg p-3 mb-5 bg-white rounded" style={{ overflowX: "auto" }}>

                <table className="table table-hover table-sm table-bordered table-responsive-sm"
                    {...getTableProps()} ref={tableRef}>
                    <thead className="thead-light">
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <th {...column.getHeaderProps(column.getSortByToggleProps())}
                                        style={{
                                            borderBottom: 'solid 1px black',
                                            color: 'black',
                                            height: "30px",
                                        }}>
                                        {column.render('Header')}
                                        <span>{column.isSorted ? column.isSortedDesc ? '▼' : '▲' : ''}</span></th>
                                ))}
                            </tr>
                        ))}
                    </thead>

                    <tbody {...getTableBodyProps()}>
                        {page.map((row) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()} onInput={() => { setSelecteddata(Object.values(selectedFlatRows.map((row) => row.original))) }} style={{ verticalAlign: 'middle',backgroundColor: row.original.Estado === 'Finalizado'? '#C4EEB1': row.original.Estado === 'Reparado'? '#ffd966':'white' }}>
                                    {row.cells.map(cell => {
                                        return <td {...cell.getCellProps()} className='text-truncate' style={{ height: "50px", overflow: 'visible' }}>{cell.render('Cell')}</td>;
                                    })}

                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
            {/* Control de paginacion */}
            <div className="col text-center">
                {/* Atras */}
                <button className="btn btn-outline-dark mx-1"
                    onClick={() => previousPage()}
                    disabled={!canPreviousPage}
                    style={{ margin: '0 auto', border: 'none', backgroundColor: '#ffffff' }}>
                    <img src={left} width="40rem" height="40rem" />
                </button>
                {/* texto Page 1 of 1 ... */}
                <span>
                    Page{' '}
                    <strong>
                        {pageIndex + 1} of {controlledPageCount}
                    </strong>{' '}
                </span>
                {/* Adelante */}
                <button className="btn btn-outline-dark"
                    onClick={() => nextPage()}
                    disabled={!canNextPage}
                    style={{ margin: '0 auto', border: 'none', backgroundColor: '#ffffff' }}>
                    <img src={next} width="40rem" height="40rem" />
                </button>

            </div>
            <br />
            {/* vertabla? <div id='btnLoading' className="spinner-border spinner-border-sm hideLoad" role="status" />  */}
        </div>
    )

}